import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
`;

export const Title = styled.h1`
  text-align: center;
  color: #333;
`;

export const SectionTitle = styled.h2`
  color: #555;
  text-align: left;
`;

export const AddedIngredientTitle = styled.h4`
  color: #555;
  text-align: center;
`;


export const IngredientList = styled.div<{ centered?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flex-start')};
`;

export const IngredientButton = styled.button<{ selected: boolean }>`
  padding: 10px 25px;
  color: #fff;
  background-color: ${({ selected }) => (selected ? '#444444' : '#17a2b8')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ selected }) => (selected ? '#999999' : '#138496')};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 10px;
  input {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
    outline: none;
    transition: border-color 0.3s;
    &:focus {
      border-color: #17a2b8;
    }
  }
`;

export const AddButton = styled.button`
  padding: 10px 20px;
  color: #fff;
  background-color: #17a2b8;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #138496;
  }
`;

export const CreateButton = styled.button`
  padding: 10px 25px;
  font-size: 1.2em;
  color: #fff;
  background-color: #17a2b8;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #138496;
  }
`;

export const Result = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
  text-align: left;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 8px solid #f3f3f3;
  border-top: 8px solid #17a2b8;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 1s linear infinite;
  margin: 20px auto;
`;
