// src/styles/theme.ts
export const theme = {
    fonts: {
      body: "'IBM Plex Mono', sans-serif",
      code: "'Fira Code', monospace"
    },
    colors: {
      primary: "#333",
      hover: "#000"
    }
  };