import React from 'react';
import { Main, Container, Description, Intro, Message, StyledButton, Image, MainContentWrapper } from '../styles/MainContentStyles';
import Projects from './Projects';

const MainContent: React.FC = () => {
  const introText: string = `
    Hi, my name is Manoli. I'm currently a member of the technical staff @ OpenAI. 
    I manage the team that is building the Assistants API. Prior to OpenAI I was at 
    YouTube leading the engineering team responsible for growing YouTube TV, NFL Sunday Ticket, 
    and Primetime Channels. Prior to that I was at Instagram supporting teams working on search, 
    and recommendation quality. 
  `;

  const reachOutMessageText: string = `
    Have feedback on the OpenAI API? Please reach out.
  `;

  return (
    <Main>
      <MainContentWrapper>
        <Container>
          <Description>
            <Intro>
              {introText}
            </Intro>
            <Message>
              {reachOutMessageText}
            </Message>
            <StyledButton href="mailto:manoli@liodakis.com">Reach Out</StyledButton>
          </Description>
          <Image src="/android-chrome-512x512.png" alt="Manoli Liodakis" />
        </Container>
        <Projects />
      </MainContentWrapper>
    </Main>
  );
};

export default MainContent;
