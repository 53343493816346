import React from 'react';
import { FooterContainer } from '../styles/FooterStyles';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <div>@2024 Manoli Liodakis</div>
    </FooterContainer>
  );
};

export default Footer;
