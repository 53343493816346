// ../styles/HeaderStyles.js or ../styles/HeaderStyles.ts
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  align-items: center;
  background-color: transparent; /* Remove background color */
  display: flex;
  font-family: ${(props) => props.theme.fonts.body}; /* Use the font from theme */
  justify-content: space-between;
  padding: 20px 00px;
`;

export const NameLink = styled.a`
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #000000; /* Color change on hover for visual feedback */
  }
`;

export const Icons = styled.div`
  display: flex;
  gap: 15px;
`;

export const Icon = styled.a`
  font-size: .8em;
  color: ${(props) => props.color}; /* Use the color passed as a prop */
  
  &:hover {
    opacity: 0.7; /* Slightly reduce opacity on hover */
  }

  & > svg {
    color: inherit; /* Ensure the icon inherits the color */
    width: 1.5em;
    height: 1.5em;
  }
`;
