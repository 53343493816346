import React, { useState } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import predefinedIngredients from '../data/ingredients';
import { 
  Container, 
  Title, 
  SectionTitle,
  AddedIngredientTitle, 
  IngredientList, 
  IngredientButton, 
  InputContainer, 
  AddButton, 
  CreateButton, 
  Result, 
  Spinner 
} from '../styles/DrinkCreatorStyles';

const DrinkCreator: React.FC = () => {
  const [selectedIngredients, setSelectedIngredients] = useState<string[]>([]);
  const [customIngredient, setCustomIngredient] = useState<string>('');
  const [result, setResult] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleIngredientChange = (ingredient: string) => {
    setSelectedIngredients((prev) =>
      prev.includes(ingredient)
        ? prev.filter((i) => i !== ingredient)
        : [...prev, ingredient]
    );
  };

  const handleCustomIngredientChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomIngredient(e.target.value);
  };

  const handleAddCustomIngredient = () => {
    if (customIngredient && !selectedIngredients.includes(customIngredient)) {
      setSelectedIngredients((prev) => [...prev, customIngredient]);
      setCustomIngredient('');
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAddCustomIngredient();
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/drink`, {
        ingredients: selectedIngredients,
      });
      setResult(response.data.recipe);
    } catch (error) {
      console.error('Error creating drink:', error);
      setResult('Failed to create drink. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Drink Creator</Title>
      <div>
        <SectionTitle>1. Select Ingredients</SectionTitle>
        <IngredientList>
          {predefinedIngredients.map((ingredient) => (
            <IngredientButton
              key={ingredient}
              selected={selectedIngredients.includes(ingredient)}
              onClick={() => handleIngredientChange(ingredient)}
            >
              {ingredient}
            </IngredientButton>
          ))}
        </IngredientList>
      </div>
      <div>
        <SectionTitle>2. Add Custom Ingredients</SectionTitle>
        <InputContainer>
          <input
            type="text"
            value={customIngredient}
            onChange={handleCustomIngredientChange}
            onKeyDown={handleKeyDown}
          />
          <AddButton onClick={handleAddCustomIngredient}>Add Ingredient</AddButton>
        </InputContainer>
      </div>
      <div>
        <SectionTitle>
          <AddedIngredientTitle>
            Selected Ingredients
          </AddedIngredientTitle>
        </SectionTitle>
        <IngredientList centered>
          {selectedIngredients.map((ingredient, index) => (
            <IngredientButton
              key={index}
              selected={true}
              onClick={() => handleIngredientChange(ingredient)}
            >
              {ingredient}
            </IngredientButton>
          ))}
        </IngredientList>
      </div>
      <br />
      <CreateButton onClick={handleSubmit} disabled={loading}>Create Drink</CreateButton>
      {loading && <Spinner />}
      {result && (
        <Result>
          <SectionTitle>Drink Recipe</SectionTitle>
          <ReactMarkdown>{result}</ReactMarkdown>
        </Result>
      )}
    </Container>
  );
};

export default DrinkCreator;
