import styled from 'styled-components';

export const Main = styled.main`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainContentWrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Description = styled.div`
  flex: 1;
  margin-right: 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px; /* Add some spacing between the image and text when stacked */
    order: 2; /* Ensure the description appears below the image */
  }
`;

export const Intro = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
`;

export const Message = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
`;

export const StyledButton = styled.a`
  background-color: #333333; /* Adjusted button color to match text */
  color: white;
  border: none;
  border-radius: 5px; /* Reduced the border-radius for less rounded edges */
  padding: 10px 40px; /* Made the button wider */
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px; /* Added spacing above the button */
  text-decoration: none;
  display: inline-block;
  align-self: flex-start; /* Left-align the button */

  &:hover {
    background-color: #555555; /* Darker background on hover */
  }
`;

export const Image = styled.img`
  flex: 1;
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 20px;

  @media (max-width: 768px) {
    margin-bottom: 20px; /* Add some spacing between the image and text when stacked */
    order: 1; /* Ensure the image appears above the description */
    max-width: 100%;
  }
`;
