import React from 'react';
import { HeaderContainer, NameLink, Icons, Icon } from '../styles/HeaderStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Header: React.FC = () => {
  return (
    <HeaderContainer>
      <NameLink href="https://liodakis.com" target="_blank" rel="noopener noreferrer">
        Manoli Liodakis
      </NameLink>
      <Icons>
        <Icon as="a" href="https://www.linkedin.com/in/liodakis" target="_blank" rel="noopener noreferrer" color="#0077B5">
          <FontAwesomeIcon icon={faLinkedin} />
        </Icon>
        <Icon as="a" href="https://x.com/liodakis" target="_blank" rel="noopener noreferrer" color="#000000">
          <FontAwesomeIcon icon={faXTwitter} />
        </Icon>
        <Icon as="a" href="mailto:manoli@liodakis.com" color="#000000">
          <FontAwesomeIcon icon={faEnvelope} />
        </Icon>
      </Icons>
    </HeaderContainer>
  );
};

export default Header;
