import React from 'react';
import DrinkCreator from '../components/DrinkCreator';

const DrinkCreatorPage: React.FC = () => {
  return (
    <div>
      <DrinkCreator />
    </div>
  );
};

export default DrinkCreatorPage;
