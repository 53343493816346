import React from 'react';
import { ProjectsContainer, ProjectList, ProjectListItem, ProjectLink, ProjectTitle } from '../styles/ProjectsStyles';

const Projects: React.FC = () => {
  return (
    <ProjectsContainer>
      <ProjectTitle>Example Projects</ProjectTitle>
      <ProjectList>
        <ProjectListItem>
          <ProjectLink to="/create-drink">Drink Creator</ProjectLink>
        </ProjectListItem>
      </ProjectList>
    </ProjectsContainer>
  );
};

export default Projects;
