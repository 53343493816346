import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ProjectsContainer = styled.div`
  width: 100%; /* Ensure it takes the full width of the parent container */
  text-align: left;
  margin-top: 50px;
`;

export const ProjectTitle = styled.div`
  font-size: 1.2em;
  font-weight: 700;
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
`;

export const ProjectList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProjectListItem = styled.li`
  margin: 0 10px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

export const ProjectLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;
