import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${(props) => props.theme.fonts.body};
  margin-top: 40px;
  border-top: 1px solid #ddd;
  padding-top: 20px;
`;