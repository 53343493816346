const predefinedIngredients = [
  'Vodka',
  'Gin',
  'Rum',
  'Tequila',
  'Whiskey',
  'Triple Sec',
  'Lemon Juice',
  'Lime Juice',
  'Orange Juice',
  'Cranberry Juice',
  'Cola',
  'Tonic Water',
  'Ginger Beer',
  'Amaretto',
  'Aperol',
  'Bitters',
  'Blue Curacao',
  'Campari',
  'Champagne',
  'Coconut Cream',
  'Coffee Liqueur',
  'Cointreau',
  'Cream',
  'Creme de Cacao',
  'Dry Vermouth',
  'Elderflower Liqueur',
  'Grenadine',
  'Irish Cream',
  'Kahlua',
  'Mint Leaves'
];

export default predefinedIngredients;
